.wrapper {
  // for customization user page
  // vars are located in @/users/ui/common/theme.scss
  background-image: var(--backgroundImage);
  background-color: var(--backgroundColor);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
}
