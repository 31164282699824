@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrp {
  display: table;
  min-height: 100%;
  min-width: 100%;
  border-collapse: collapse;

  i.icon {
    width: 26px;
    height: 26px;
    fill: #4ab675;
    margin-bottom: 20px;
  }
}

.aligner {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  transform: translateY(-36px);
}

.container {
  text-align: center;
  vertical-align: middle;
  max-width: 400px;
  margin: 66px auto 30px;
}

.isWide {
  max-width: 600px;
}

.isExtraWide {
  max-width: 1000px;
}

.logoWrp {
  height: 26px;
  margin: 0 auto;
  display: inline-block;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }

  img {
    max-width: 200px;
    max-height: 80px;
  }
}

.logo {
  fill: #5d3b84;
}

.title {
  margin: 0 0 14px;
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  color: $secondary3Color;
  margin-bottom: 20px;
  padding: 0 60px;

  @include mqMaxWidth($screenXs) {
    padding: 0 20px;
    font-size: 12px;
    line-height: 15px;
  }
}

.errorMessage {
  color: $cancelColor;
}

.contentWrp {
  position: relative;
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.content {
  padding: 40px 0;

  @include mqMaxWidth($screenXs) {
    padding: 30px 0;
  }
}

.innerContent {
  padding: 0 60px;

  @include mqMaxWidth($screenXs) {
    padding: 0 20px;
  }
}

.footer {
  background: rgba(0, 0, 0, 0.05);
  padding: 25px;
  color: #616161;
  font-weight: 500;
}

.copyright {
  margin-top: 30px;
  color: $secondary3Color;
  font-size: 14px;

  svg {
    display: inline-block;
    position: relative;
    width: 29px;
    top: 2px;
    margin-right: 10px;
    fill: #8c8c8c;
  }
}

.bottomLink {
  display: inline-block;
  margin-top: 30px;
  font-weight: 500;
}
