@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

:root {
  --backgroundColor: #{$gray8};
  --tileColor: #{$ColorGrayscaleWhite};
  --primaryTextColor: #{$ColorGrayscaleBlack};
  --secondaryTextColor: #{$secondary3Color};
  --linkColor: #{$ColorPrimaryBlue};
  --inputLabelColor: #{$gray4};
  --buttonTextColor: #{$ColorGrayscaleWhite};
  --buttonColor: #{$purple};
  --backgroundImage: none;
}

.button {
  &:not(:disabled) {
    background-color: var(--buttonColor) !important;
    color: var(--buttonTextColor) !important;

    &:hover,
    &:focus {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) var(--buttonColor);
      color: var(--buttonTextColor);
    }
  }
}

.tile {
  background-color: var(--tileColor) !important;
}

.title {
  color: var(--primaryTextColor);
}

.secondaryText {
  color: var(--secondaryTextColor) !important;

  a {
    color: var(--linkColor) !important;

    &:hover {
      color: var(--linkColor);
      filter: brightness(0.8);
    }
  }
}

.link {
  color: var(--linkColor) !important;

  i {
    fill: var(--linkColor);
  }

  &:hover {
    color: var(--linkColor);
    filter: brightness(0.8);
  }
}

.label {
  color: var(--inputLabelColor);
}

.input {
  label {
    color: var(--inputLabelColor);

    > span > span {
      color: var(--inputLabelColor);

      svg {
        fill: var(--inputLabelColor);
      }
    }

    i {
      opacity: 0.5;
    }
  }
}
